import axios from "axios";

const API = axios.create({
    baseURL: 'https://clientsdemoarea.com/projects/ctz_jersey/api',
    //baseURL: 'https://ctj.je/CitizenshipTestAdmin/api',
    withCredentials: true,
    // xsrfCookieName: "XSRF-TOKEN",
    // xsrfHeaderName: "X-XSRF-TOKEN",
});

export default API;